<template>
  <router-view />
</template>
<style>
.section-padding {
  padding-top: 240px !important;
}
.header-fixx {
  position: fixed; /* fixing the position takes it out of html flow - knows
                   nothing about where to locate itself except by browser
                   coordinates */
  left: 0; /* top left corner should start at leftmost spot */
  top: 0; /* top left corner should start at topmost spot */
  width: 100vw; /* take up the full browser width */
  z-index: 200; /* high z index so other content scrolls underneath */
  height: 140px; /* define height for content */
}
.zoom-img {
  cursor: zoom-in;
}
.animate__delay-6s {
  -webkit-animation-delay: 6s;
  animation-delay: 6s;
}
.animate__delay-6s {
  -webkit-animation-delay: 6s;
  animation-delay: 6s;
}
.animate__delay-7s {
  -webkit-animation-delay: 7s;
  animation-delay: 7s;
}
.animate__delay-8s {
  -webkit-animation-delay: 8s;
  animation-delay: 8s;
}
.animate__delay-9s {
  -webkit-animation-delay: 9s;
  animation-delay: 9s;
}
.animate__delay-10s {
  -webkit-animation-delay: 10s;
  animation-delay: 10s;
}
.animate__delay-11s {
  -webkit-animation-delay: 11s;
  animation-delay: 11s;
}
.animate__delay-12s {
  -webkit-animation-delay: 12s;
  animation-delay: 12s;
}
.animate__delay-13s {
  -webkit-animation-delay: 13s;
  animation-delay: 13s;
}
.animate__delay-14s {
  -webkit-animation-delay: 14s;
  animation-delay: 14s;
}
.animate__delay-15s {
  -webkit-animation-delay: 15s;
  animation-delay: 15s;
}

.animate__delay-16s {
  -webkit-animation-delay: 16s;
  animation-delay: 16s;
}
.animate__delay-17s {
  -webkit-animation-delay: 17s;
  animation-delay: 17s;
}
.animate__delay-18s {
  -webkit-animation-delay: 18s;
  animation-delay: 18s;
}
.animate__delay-19s {
  -webkit-animation-delay: 19s;
  animation-delay: 19s;
}
.animate__delay-20s {
  -webkit-animation-delay: 20s;
  animation-delay: 20s;
}
</style>
<!--<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>-->
