<template>
  <footer class="site-footer">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-12 mb-4">
          <img src="images/logo.png" class="logo img-fluid" alt="" />
        </div>

        <div class="col-lg-4 col-md-6 col-12 mb-4">
          <h5 class="site-footer-title mb-3">Quick Links</h5>

          <ul class="footer-menu">
            <li class="footer-menu-item">
              <a href="#" class="footer-menu-link">About Us</a>
            </li>

            <li class="footer-menu-item">
              <a href="#" class="footer-menu-link">Newsroom</a>
            </li>

            <li class="footer-menu-item">
              <a href="#" class="footer-menu-link">Events</a>
            </li>

            <li class="footer-menu-item">
              <a href="#" class="footer-menu-link">Become a member</a>
            </li>

            <li class="footer-menu-item">
              <a href="#" class="footer-menu-link">Sign In</a>
            </li>
          </ul>
        </div>

        <div class="col-lg-4 col-md-6 col-12 mx-auto">
          <h5 class="site-footer-title mb-3">Contact Infomation</h5>

          <p class="text-white d-flex mb-2">
            <i class="bi-telephone me-2"></i>

            <a href="tel: +62366-22593" class="site-footer-link">
              +62366-22593
            </a>
          </p>

          <p class="text-white d-flex">
            <i class="bi-envelope me-2"></i>

            <a href="mailto:info@aryasimpangan.com" class="site-footer-link">
              info@aryasimpangan.com
            </a>
          </p>

          <p class="text-white d-flex mt-3">
            <i class="bi-geo-alt me-2"></i>
            Br. Kanginan Ds. Pesinggahan Kec. Dawan Klungkung Bali - Indonesia
          </p>

          <a href="#" class="custom-btn btn mt-3">Get Direction</a>
        </div>
      </div>
    </div>

    <div class="site-footer-bottom">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-7 col-12">
            <p class="copyright-text mb-0">
              Copyright © 2023
              <a href="#">Pasemetonan Pratisentana Arya Simpangan</a> Website.
            </p>
          </div>

          <div
            class="col-lg-6 col-md-5 col-12 d-flex justify-content-center align-items-center mx-auto"
          >
            <ul class="social-icon">
              <li class="social-icon-item">
                <a href="#" class="social-icon-link bi-twitter"></a>
              </li>

              <li class="social-icon-item">
                <a href="#" class="social-icon-link bi-facebook"></a>
              </li>

              <li class="social-icon-item">
                <a href="#" class="social-icon-link bi-instagram"></a>
              </li>

              <li class="social-icon-item">
                <a href="#" class="social-icon-link bi-linkedin"></a>
              </li>

              <li class="social-icon-item">
                <a
                  href="https://youtube.com/templatemo"
                  class="social-icon-link bi-youtube"
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {},
};
</script>
