<template>
  <header-site selected-menu="home"></header-site>
  <main>
    <section class="hero-section hero-section-full-height">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12 col-12 p-0">
            <div
              id="hero-slide"
              class="carousel carousel-fade slide"
              data-bs-ride="carousel"
              data-bs-interval="4000"
              data-bs-wrap="true"
            >
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img
                    src="/images/slide/ulundanuberatan.png"
                    class="carousel-image img-fluid animate__animated animate__zoomIn"
                    alt="ulundanuberatan"
                  />

                  <div
                    class="carousel-caption d-flex flex-column justify-content-end"
                  >
                    <h1 class="animate__animated animate__fadeInDown">
                      Om Swastyastu
                    </h1>

                    <p
                      class="p-carousel animate__animated animate__fadeInDown animate__delay-1s"
                    >
                      Selamat datang di website resmi
                    </p>
                    <p
                      class="p-carousel animate__animated animate__fadeInDown animate__delay-2s"
                    >
                      Pasemetonan pratisentana Arya Simpangan
                    </p>
                  </div>
                </div>

                <div class="carousel-item">
                  <img
                    src="/images/slide/canang.png"
                    class="carousel-image img-fluid animate__animated animate__zoomIn"
                    alt="sesajen"
                  />

                  <div
                    class="carousel-caption d-flex flex-column justify-content-end"
                  >
                    <h1 class="animate__animated animate__fadeInUp">Infokom</h1>

                    <p
                      class="animate__animated animate__fadeInUp animate__delay-1s"
                    >
                      Media informasi dan komunikasi yang uptodate
                    </p>
                  </div>
                </div>

                <div class="carousel-item">
                  <img
                    src="/images/slide/penari.png"
                    class="carousel-image img-fluid animate__animated animate__zoomIn"
                    alt="tarian"
                  />

                  <div
                    class="carousel-caption d-flex flex-column justify-content-end"
                  >
                    <h1 class="animate__animated animate__fadeInDown">
                      Soliditas
                    </h1>

                    <p
                      class="p-carousel animate__animated animate__fadeInDown animate__delay-1s"
                    >
                      Mempererat persaudaraan antar pratisentana
                    </p>
                    <p
                      class="p-carousel animate__animated animate__fadeInDown animate__delay-2s"
                    >
                      Arya Simpangan
                    </p>
                  </div>
                </div>
              </div>

              <button
                class="carousel-control-prev"
                type="button"
                data-bs-target="#hero-slide"
                data-bs-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Previous</span>
              </button>

              <button
                class="carousel-control-next"
                type="button"
                data-bs-target="#hero-slide"
                data-bs-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <footer-site></footer-site>
</template>

<script>
// @ is an alias to /src
import HeaderSite from "@/components/HeaderSite.vue";
import FooterSite from "@/components/FooterSite.vue";

export default {
  name: "HomeView",
  components: {
    HeaderSite,
    FooterSite,
  },
};
</script>
<style scoped>
.p-carousel {
  margin-bottom: 0px;
}
</style>
