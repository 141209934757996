import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { LoadingPlugin } from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
// Your app initialization logic goes here
/*const app = createApp(App);
app.use(store);
app.use(router);
app.use(LoadingPlugin);
app.mount("#app");*/

createApp(App).use(store).use(router).use(LoadingPlugin).mount("#app");
