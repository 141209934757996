import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import { auth } from "../api/firebaseconfig";
import store from "@/store/index";

//const store = useStore();

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { requiresAuth: false },
  },
  {
    path: "/aboutus",
    name: "aboutus",
    component: () => import("@/views/AboutUsView.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/pengurus",
    name: "pengurus",
    component: () => import("@/views/PengurusView.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/gallery",
    name: "gallery",
    component: () => import("@/views/GalleryView.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/news",
    name: "news",
    component: () => import("@/views/NewsView.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/events",
    name: "events",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("@/views/EventsView.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/signin",
    name: "signin",
    component: () => import("@/views/SignInView.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import("@/views/SignUpView.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/views/DashboardView.vue"),
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to) => {
  let isLoggedIn = false;

  await auth.onAuthStateChanged((user) => {
    if (user) {
      isLoggedIn = true;
      store.dispatch("fetchUser", user);
    } else {
      isLoggedIn = false;
    }
  });

  if (to.meta.requiresAuth && !isLoggedIn) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    console.log("=====FROBIDDEN PAGE===========");
    return {
      path: "/signin",
      // save the location we were at to come back later
      //query: { redirect: to.fullPath },
    };
  }
});

export default router;
