import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDZ-5c8XMJJyy2WgnY0lrBZNPgZ4jneFqU",
  authDomain: "arya-simpangan-apps-alpha.firebaseapp.com",
  projectId: "arya-simpangan-apps-alpha",
  storageBucket: "arya-simpangan-apps-alpha.appspot.com",
  messagingSenderId: "378178740748",
  appId: "1:378178740748:web:f081b34bbc04dc67514429",
  measurementId: "G-FYV6DGS5SP",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth();

export { app, analytics, auth };
