<template>
  <div class="header-fixx">
    <header class="site-header">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-12 d-flex flex-wrap">
            <p class="d-flex me-4 mb-0">
              <i class="bi-geo-alt me-2"></i>
              Br.Kangingan Ds.Pesinggahan Kec.Dawan Klungkung - Bali
            </p>

            <p class="d-flex mb-0">
              <i class="bi-envelope me-2"></i>

              <a href="mailto:info@aryasimpangan.com">
                info@aryasimpangan.com
              </a>
            </p>
          </div>

          <div class="col-lg-3 col-12 ms-auto d-lg-block d-none">
            <ul class="social-icon">
              <li class="social-icon-item">
                <a href="#" class="social-icon-link bi-twitter"></a>
              </li>

              <li class="social-icon-item">
                <a href="#" class="social-icon-link bi-facebook"></a>
              </li>

              <li class="social-icon-item">
                <a href="#" class="social-icon-link bi-instagram"></a>
              </li>

              <li class="social-icon-item">
                <a href="#" class="social-icon-link bi-youtube"></a>
              </li>

              <li class="social-icon-item">
                <a href="#" class="social-icon-link bi-whatsapp"></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
    <nav class="navbar navbar-expand-lg bg-light shadow-lg">
      <div class="container">
        <a class="navbar-brand" href="index.html">
          <img
            src="images/logo.png"
            class="logo img-fluid"
            alt="Kind Heart Charity"
          />
          <span>
            WEBSITE ARYA SIMPANGAN
            <small
              >Media Informasi Pasemetonan Pratisentana Arya Simpangan</small
            >
          </span>
        </a>

        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item">
              <router-link
                :to="{ path: '/' }"
                class="nav-link click-scroll"
                :class="{ active: selectedMenu === 'home' }"
                >Home</router-link
              >
            </li>

            <li class="nav-item">
              <router-link
                :to="{ path: '/aboutus' }"
                class="nav-link click-scroll"
                :class="{ active: selectedMenu === 'aboutus' }"
                >About Us</router-link
              >
            </li>

            <li class="nav-item">
              <router-link
                :to="{ path: '/pengurus' }"
                class="nav-link click-scroll"
                :class="{ active: selectedMenu === 'pengurus' }"
                >Pengurus</router-link
              >
            </li>

            <li class="nav-item">
              <router-link
                :to="{ path: '/gallery' }"
                class="nav-link click-scroll"
                :class="{ active: selectedMenu === 'gallery' }"
                >Gallery</router-link
              >
            </li>

            <li class="nav-item">
              <router-link
                :to="{ path: '/news' }"
                class="nav-link click-scroll"
                :class="{ active: selectedMenu === 'news' }"
                >News</router-link
              >
            </li>

            <li v-if="user.loggedIn" class="nav-item">
              <router-link
                :to="{ path: '/dashboard' }"
                class="nav-link click-scroll"
                :class="{ active: selectedMenu === 'dashboard' }"
                >Dashboard</router-link
              >
            </li>

            <li class="nav-item">
              <router-link
                :to="{ path: '/events' }"
                class="nav-link click-scroll"
                :class="{ active: selectedMenu === 'events' }"
                >Events</router-link
              >
            </li>

            <li class="nav-item ms-3">
              <a
                v-if="user.loggedIn"
                @click.prevent="signOut"
                class="nav-link custom-btn custom-border-btn btn"
                >Sign Out</a
              >
              <router-link
                v-else
                :to="{ path: '/signin' }"
                class="nav-link custom-btn custom-border-btn btn"
                >Sign In</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed } from "vue";
import { useLoading } from "vue-loading-overlay";

export default {
  props: ["selectedMenu"],
  setup() {
    const store = useStore();
    const router = useRouter();
    const $loading = useLoading({
      //active: isLoading,
      //isFullPage: true,
    });

    const user = computed(() => {
      return store.getters.user;
    });

    const signOut = async () => {
      const loader = $loading.show({
        active: true,
        isFullPage: true,
        color: "#5bc1ac",
        loader: "spinner",
      });
      await store.dispatch("logOut");
      loader.hide();
      router.push("/signin");
    };

    return { user, signOut };
  },
};
</script>
